import {GET_ARTICLES} from "~/graphql/articles"
import type {Article, ArticleEdge} from "~/graphql/__generated__/graphql"
import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
import articleLocalFactory from "~/libs/factories/ArticleLocalFactory"
import type CategoryLocal from "~/libs/interfaces/CategoryLocal"
import {PersistedStoreType, usePersistedStore} from "~/stores/usePersistedStore"
import {useArticleCategoryIdSearch} from "~/composables/useArticleCategoryIdSearch"
import {useApiIdConverter} from "~/composables/useApiIdConverter"
import type AiTopicLocal from "~/libs/interfaces/AiTopicLocal";

const logger = useFrontendLogger()

export const useReaderStore = defineStore('reader', {
  state: () => {
    const state: {
      isLoading: Ref<boolean>
      articles: ArticleLocal[]
      article?: Ref<ArticleLocal>

    } = {
      isLoading: ref(false),
      articles: reactive([]),
    }
    return state
  },
  getters: {
    getIsLoading(state): boolean {
      return state.isLoading
    },
    getIsFilterActive(): boolean {
      const persistedStore = usePersistedStore()
      if (persistedStore.getIsReaderFulltextActive) {
        return true
      }
      if (persistedStore.readerSelectedCategories?.length > 0) {
        return true
      }
      return false
    },
    getShowMainArticle(): boolean {
      const config = useRuntimeConfig()
      return config.public.showFeedMainArticle && !this.getIsFilterActive
    },
    getFeedArticlesOffset(): number {
      return this.getShowMainArticle ? 1 : 0
    }
  },
  actions: {
    /**
     * Get categories with filtered articles - for category list
     * @param articleOffset
     */
    getFilteredCategories(articleOffset: number = 0): CategoryLocal[] | AiTopicLocal[] {
      // console.log(new Date().getMilliseconds(), 'getFilteredCategories START')
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()

      const persistedStore = usePersistedStore()

      const topics: AiTopicLocal[] = []
      this.getFilteredArticles().forEach((article) => {
        if (useAiArticles && article.aiTopicList && article.aiTopicList.edges && article.aiTopicList.edges?.length) {
          article.aiTopicList.edges.forEach((topic) => {
            if (topic?.node?.id &&
              topic?.node?.name) {
              const existingTopic = topics.find((fTopic) => {
                return fTopic.id === topic?.node?.id
              })
              if (!existingTopic) {
                let isSelected = true
                let hasArticles = false
                if (persistedStore.readerSelectedCategories.length > 0 && !persistedStore.readerSelectedCategories.includes(topic?.node?.id)) {
                  isSelected = false
                }
                if (isSelected) {
                  hasArticles = this.hasCategoryArticles(topic?.node?.id, articleOffset)
                }
                topics.push({
                  ...(topic.node),
                  isVisible: isSelected && hasArticles
                })
              }
            }
          })
        } else if (article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id &&
              category?.node?.name) {
              const existingCategory = topics.find((fCategory) => {
                return fCategory.id === category?.node?.id
              })
              if (!existingCategory) {
                let isSelected = true
                let hasArticles = false
                if (persistedStore.readerSelectedCategories.length > 0 && !persistedStore.readerSelectedCategories.includes(category?.node?.id)) {
                  isSelected = false
                }
                if (isSelected) {
                  hasArticles = this.hasCategoryArticles(category?.node?.id, articleOffset)
                }
                topics.push({
                  id: category.node.id,
                  name: category.node.name,
                  isDeleted: category.node.isDeleted,
                  isVisible: isSelected && hasArticles
                })
              }
            }
          })
        }
      })

      return topics
    },
    /**
     * Get all categories
     */
    getCategories(): CategoryLocal[] | AiTopicLocal[] {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()


      const topics: AiTopicLocal[] = []
      this.articles.forEach((article) => {
        if (useAiArticles && article.aiTopicList && article.aiTopicList.edges && article.aiTopicList.edges?.length) {
          article.aiTopicList.edges.forEach((topic) => {
            if (topic?.node?.id && topic?.node?.name) {
              const existingTopic = topics.find((fTopic) => {
                return fTopic.id === topic?.node?.id
              })
              if (!existingTopic) {
                topics.push({
                  ...(topic.node),
                  isVisible: true
                })
              }
            }
          })
        } else if (article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id && category?.node?.name) {
              const existingCategory = topics.find((fCategory) => {
                return fCategory.id === category?.node?.id
              })
              if (!existingCategory) {
                topics.push({
                  id: category.node.id,
                  name: category.node.name,
                  isDeleted: category.node.isDeleted,
                  isVisible: true
                })
              }
            }
          })
        }
      })
      return topics
    },
    /**
     * Get available categories for user preset (selected categories, magazines, authors) - for menu
     * @param removeNotVisible
     */
    getAvailableCategories(removeNotVisible = false): CategoryLocal[] | AiTopicLocal[] {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()

      const myArticlesStore = useMyArticlesStore()

      const topics: AiTopicLocal[] = []
      this.articles.forEach((article) => {
        let isArticleVisible = true

        if (myArticlesStore.storedIds && !myArticlesStore.storedIds.includes(article.id)) {
          isArticleVisible = false
        }

        if (!removeNotVisible || isArticleVisible) {
          if (useAiArticles  && article.aiTopicList && article.aiTopicList.edges && article.aiTopicList.edges?.length) {
            article.aiTopicList.edges.forEach((topic) => {
              if (topic?.node?.id && topic?.node?.name) {
                const existingCategory = topics.find((fTopic) => {
                  return fTopic.id === topic?.node?.id
                })
                let isVisible = isArticleVisible

                if (!existingCategory && (!removeNotVisible || isVisible)) {
                  topics.push({
                    ...(topic.node),
                    isVisible: true
                  })
                }
              }
            })
          } else if (article.categoryList && article.categoryList.edges) {
            article.categoryList.edges.forEach((category) => {
              if (category?.node?.id && category?.node?.name) {
                const existingCategory = topics.find((fCategory) => {
                  return fCategory.id === category?.node?.id
                })
                let isVisible = isArticleVisible

                if (!existingCategory && (!removeNotVisible || isVisible)) {
                  topics.push({
                    id: category.node.id,
                    name: category.node.name,
                    isDeleted: category.node.isDeleted,
                    isVisible: true
                  })
                }
              }
            })
          }
        }
      })

      return topics
    },
    /**
     * Check if category has some articles after filtering
     * @param categoryId
     * @param offset
     */
    hasCategoryArticles(categoryId: string, offset: number = 0): boolean {
      const articles = this.getArticlesForCategory(categoryId, offset).filter((article) => {
        return article.isVisible
      })
      return articles.length > 0
    },
    /**
     * Get articles with applied filters and presets
     * @param offset
     * @param limit
     */
    getFilteredArticles(offset: number = 0, limit: number = 100): ArticleLocal[] {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()

      const persistedStore = usePersistedStore()
      persistedStore.cleanUpSelectedCategories(PersistedStoreType.READER, this.getCategories())

      const myArticlesStore = useMyArticlesStore()
      let visibleCount = 0
      let trendingCount = 0
      return this.articles.map((article) => {
        let isVisible = true
        // show only saved articles
        if (myArticlesStore.storedIds && !myArticlesStore.storedIds.includes(article.id)) {
          isVisible = false
        }

        // fulltext filter
        if (isVisible) {
          isVisible = useArticleFulltextSearch(useAiArticles, article, persistedStore.readerFulltext)
        }

        // Quick filter - filter categories
        if (isVisible) {
          isVisible = useArticleCategoryIdSearch(useAiArticles, article, persistedStore.readerSelectedCategories as string[])
        }

        if (isVisible && visibleCount < offset) {
          isVisible = false
          visibleCount = visibleCount + 1
        }

        if (isVisible) {
          trendingCount = trendingCount + 1
        }

        if (limit < trendingCount) {
          isVisible = false
        }

        return {
          ...article,
          isVisible,
          trendingOrder: trendingCount
        }
      })
    },
    /**
     * Get count of getFilteredArticles
     * @param offset
     */
    getFilteredArticlesCount(offset: number = 0): number {
      const articles = this.getFilteredArticles(offset).filter(article => article.isVisible)
      return articles.length
    },
    /**
     * Get article by articleId
     * @param id
     */
    getArticle(id: string): ArticleLocal | undefined {
      return this.articles.find((article) => {
        return article.id.indexOf(id) !== -1
      })
    },
    /**
     * Get category by categoryId
     * @param id
     */
    getCategory(id: string): CategoryLocal | AiTopicLocal | undefined {
      return this.getFilteredCategories().find((category) => {
        return category.id.indexOf(id) !== -1
      })
    },
    /**
     * Get filtered articles for category by categoryId
     * @param categoryId
     * @param offset
     * @param limit
     */
    getArticlesForCategory(categoryId: string, offset: number = 0, limit: number = 3): ArticleLocal[] {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      // console.log(new Date().getMilliseconds(), 'getArticlesForCategory START')
      const articles: ArticleLocal[] = []
      let visibleCount = 0
      // TODO - performance!!!
      this.getFilteredArticles(offset).forEach((article) => {
        if (limit > 0 && visibleCount >= limit) {
          return
        }
        if (useAiArticles && article.aiTopicList && article.aiTopicList.edges && article.aiTopicList.edges?.length) {
          article.aiTopicList.edges.forEach((topic) => {
            if (topic?.node?.id && topic?.node?.id === categoryId) {
              articles.push(article)
              if (article.isVisible) {
                visibleCount = visibleCount + 1
              }
            }
          })
        } else if (article.categoryList && article.categoryList.edges) {
          article.categoryList.edges.forEach((category) => {
            if (category?.node?.id && category?.node?.id === categoryId) {
              articles.push(article)
              if (article.isVisible) {
                visibleCount = visibleCount + 1
              }
            }
          })
        }
      })
      // console.log(new Date().getMilliseconds(), 'getArticlesForCategory END')
      return articles
    },
    /**
     * Get articles count by categoryId
     * @param categoryId
     * @param offset
     */
    getArticlesForCategoryCount(categoryId: string, offset: number = 0): number {
      const articles = this.getArticlesForCategory(categoryId, offset, 0)
      return articles.filter(article => article.isVisible).length
    },
    /**
     * Fetch articles from server
     * @param limit
     */
    async fetchArticles(limit: number = 100): Promise<void> {
      const myArticlesStore = useMyArticlesStore()
      const missingIds = myArticlesStore.storedIds.filter((id: string) => {
        return !this.articles.find((article) => {
          return article.id === id
        })
      })

      // nothing new to load
      if (!missingIds || missingIds.length === 0) {
        return
      }

      this.isLoading = true

      const apiIdToUuid = useApiIdConverter().apiIdToUuid
      let missingUuids = missingIds.map(apiIdToUuid)

      const filter = {
        first: limit,
        isDeleted: false,
        isCompleted: true,
        isPreview: false,
        idList: missingUuids // get only saved and missing IDs
      }

      if (import.meta.server) {
        try {
          const result = await useAsyncQuery(GET_ARTICLES, filter)
          if (result.error?.value) {
            logger?.error('useReaderStore:fetchArticles:server - error', {errorDetail: result.error?.value})
          }

          //@ts-ignore
          const edges = result.data?.value?.articles?.edges || []
          const newArticles = edges.map((item: ArticleEdge) => item.node)
          this.addArticles(newArticles)
        } catch (e) {
          logger?.error('useReaderStore:fetchArticles:server - exception', {errorDetail: e})
        }
        this.isLoading = false
      } else {
        const {onResult, onError} = useQuery(GET_ARTICLES, filter)
        onResult((result) => {
          this.isLoading = result.loading
          if (!result.loading) {
            const data = result.data?.articles?.edges || []
            if (data.length) {
              const newArticles = data.map((item: ArticleEdge) => item.node)
              this.addArticles(newArticles)
            }
          }
        })
        onError((error) => {
          this.isLoading = false
          logger?.error('useReaderStore:fetchArticles:client - error', {errorDetail: error})
        })
      }
    },
    /**
     * Add articles to store - remove duplicities
     * @param newArticles
     * @param rewrite
     */
    addArticles(newArticles: Article[], rewrite: boolean = false): void {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      newArticles.forEach((newArticle) => {
        const articleLocal = articleLocalFactory.createArticleLocal(newArticle, useAiArticles)
        const foundIndex = this.articles.findIndex(article => article.id === newArticle.id)
        if (foundIndex >= 0 && rewrite) {
          this.articles.splice(foundIndex, 1)
        }
        if (foundIndex === -1 || rewrite) {
          this.articles.push(articleLocal)
        }
      })

      // sort articles
      this.articles.sort((a, b) => {
        if (a.publishedDate && b.publishedDate) {
          return a.publishedDate?.getTime() > b?.publishedDate?.getTime() ? -1 : 1
        }
        return 1
      })
    },
  }
})
