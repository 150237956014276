<template>
  <div class="dont-hide-menu -mx-7">
    <div
      class="menu--filters flex gap-2 items-center overflow-x-scroll overflow-y-visible scrollbar-hide mx-auto max-w-min -mb-3 pb-10 p-7 pointer-events-auto"
      v-dragscroll="isDesktop"
      v-on:dragscrollstart="onScrollStart"
      v-on:dragscrollend="onScrollEnd"
    >
      <div
        v-for="category in categories"
        class="filter-item text-nowrap whitespace-nowrap relative p-4 bg-white text-black rounded-full text-sm pointer-events-auto"
        @click="scrollToTopDebounce()"
      >
        {{ category.name }}
        <label class="label" for="checkbox">
          <input
            v-if="!scrollingEnabled"
            :value="category.id"
            :id="`${idPrefix || 'category'}_${category.id}`"
            class="absolute inset-0 appearance-none w-full h-full cursor-pointer"
            type="checkbox"
            v-model="model"
          />
        </label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type {PropType} from "vue";
import {useScrollToTopDebounce} from "~/composables/useScrollToTopDebounce";
import type CategoryLocal from "~/libs/interfaces/CategoryLocal";
import type AiTopicLocal from "~/libs/interfaces/AiTopicLocal";
const { isDesktop } = useDevice();

const model = defineModel({required: false})
const scrollingEnabled = ref(false)
const scrollToTopDebounce = useScrollToTopDebounce()

const {
  categories,
  idPrefix
} = defineProps({
  categories: {type: Array as PropType<CategoryLocal[] | AiTopicLocal[]>, required: true},
  idPrefix: {type: String, required: false}
})

const onScrollStart = () => {
  scrollingEnabled.value = true
}
const onScrollEnd = () => {
  scrollingEnabled.value = false
}


</script>
