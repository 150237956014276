<template>
  <div class="mx-auto" :class="{'sm:block hidden': isSearchActive }">
    <!-- desktop swipe -->
    <transition name="slide-fade">
      <NavigationCategories
        v-if="persistedStore.showMenu && route.name == routes.feed.name"
        v-model="persistedStore.feedSelectedCategories"
        :categories="articlesStore.getAvailableCategories(true)"
        id-prefix="feed_categories"
      />
    </transition>
    <transition name="slide-fade">
      <NavigationCategories
        v-if="persistedStore.showMenu && route.name == routes.reader.name"
        v-model="persistedStore.readerSelectedCategories"
        :categories="readerStore.getAvailableCategories(true)"
        id-prefix="reader_categories"
      />
    </transition>
    <transition name="slide-fade">
      <NavigationCategories
        v-if="persistedStore.showMenu && route.name == routes.trending.name"
        v-model="persistedStore.trendingSelectedCategories"
        :categories="trendingStore.getAvailableCategories(true)"
        id-prefix="trending_categories"
      />
    </transition>

    <!-- main menu ( the transitions on mobile are only on hover ) -->
    <transition name="slide-fade">
      <div v-if="persistedStore.showMenu" class="dont-hide-menu relative">
        <NavigationMainMenu is-ghost />
        <NavigationMainMenu />
      </div>
    </transition>
    <!-- main menu ( the transitions on mobile are only on hover ) -->
  </div>
</template>

<script setup lang="ts">
import {onMounted} from 'vue'
const { isDesktop } = useDevice()
import routes from "~/libs/configs/routes"

const {
  isSearchActive
} = defineProps({
  isSearchActive: {type: Boolean, required: false, default: false}
})

const route = useRoute()
const persistedStore = usePersistedStore()
const articlesStore = useArticlesStore()
const trendingStore = useTrendingStore()
const readerStore = useReaderStore()

enum DIRECTION {
  UP = 'up',
  DOWN = 'down'
}

const threshold = 50
let timeout: ReturnType<typeof setTimeout> | undefined
let scrollTimeout: ReturnType<typeof setTimeout> | undefined
let position = 0
let direction = null
let ignore = false

onMounted(() => {
  if (!window) {
    return
  }
  window?.addEventListener("scroll", handleScrollDebounce);
  position = window.scrollY
})
onUnmounted(() => {
  if (!window) {
    return
  }
  window?.removeEventListener("scroll", handleScrollDebounce);

})

const handleScrollDebounce = (e:Event) => {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }
  scrollTimeout = setTimeout(() => handleScroll(e), 10)
}

const handleScroll = (e: Event) => {
  if (isDesktop && persistedStore.showMenu && document.querySelectorAll( ".dont-hide-menu:hover").length) {
    return
  }
  const currentScroll = window.scrollY

  if (ignore) {
    return
  }

  if (Math.abs(currentScroll - position) < threshold) {
    return
  }

  if (currentScroll < position && persistedStore.showMenu) {
    position = currentScroll
    return
  }
  if (currentScroll > position && !persistedStore.showMenu) {
    position = currentScroll
    return
  }

  if (currentScroll < 0 || currentScroll > (document.body.scrollHeight - window.innerHeight)) {
    ignore = true
    clearTimeout(timeout)
    setTimeout(() => {
      ignore = false
    }, 50)
    return
  }

  if (currentScroll > position) {
    direction = DIRECTION.UP
  } else {
    direction = DIRECTION.DOWN
  }


  if (currentScroll < position) {
    persistedStore.showMenu = true
    if (timeout) {
      clearTimeout(timeout)
      timeout = undefined
    }
  } else {
    if (!timeout) {
      timeout = setTimeout(() => {
        persistedStore.showMenu = false
      }, 200)
    }
  }
  position = currentScroll
}

</script>

<style lang="postcss" scoped>

.menu--filters .filter-item {
  @apply shadow-custom1
}
</style>
