<template>
  <div
    ref="searchInputContainer"
    class="pointer-events-auto"
    :class="{'w-full md:w-auto': props.isSearchActive}"
  >
    <div
      class="relative md:relative z-50 md:!top-auto md:bottom-0 inset-x-0 mx-0 md:mx-0 w-full text-search "
      :class="{'hidden md:block': !props.isSearchActive}"
    >
      <!--<Teleport :disabled="true || !isMobile" to="#ios-search-hack-container-teleport">-->

      <div v-if="!isMobile || props.isSearchActive"
           class="shadow-custom1 search-form relative bg-white flex rounded-full h-[54px] md:h-10 lg:h-[50px] pl-4"
      >
        <button class="mx-auto">
          <IconsSearch class="w-3 h-3"/>
        </button>
        <input
          type="search"
          ref="searchInput"
          class="search-form__input relative flex-1 min-w-52 md:min-w-60 lg:min-w-72 block text-base sm:text-sm lg:text-base font-normal text-black py-3 px-2 outline-none bg-transparent"
          v-model="fulltext"
          :placeholder="props.placeholder"
          @focus="focus"
          @blur="blur"
          @input="input"
          v-on:keyup.enter="$event.target?.blur()"
        >
        <div
          class="search-form__reset right-0 left-auto w-11 h-11 md:w-8 md:h-8 lg:h-10 lg:w-10 p-1 m-1.5 md:m-[4px]  lg:m-[5px] rounded-full bg-black shadow-2xl transition-opacity fill-white"
          @click="cancelSearch"
        >
          <IconsCancel />
        </div>
      </div>
<!--      </Teleport>-->
    </div>
    <!-- mobile search button -->
    <div v-if="!props.isSearchActive" class="block md:hidden w-10 h-10">
      <button class="search-form__icon mx-auto bg-white rounded-full w-10 h-10 p-0 m-0" @click="search"><IconsSearch class="mx-auto w-3 h-3"/></button></div>
  </div>
</template>

<script setup lang="ts">
const { isMobile } = useDevice();
import {onMounted} from "vue";

const model = defineModel()
const emits = defineEmits(['cancelSearch', 'openSearch', 'startTyping', 'endTyping', 'cancelSearchTeleport'])

const fulltext = ref(model.value)
const searchInput = ref(null)
const searchInputContainer = ref(null)
const isSearching = ref(false)
const isFocused = ref(false)
let inputDebounceTimeout

const props = defineProps({
  placeholder: {type: String, required: false, default: ""},
  isSearchActive: {type: Boolean, required: false, default: false}
})

watch(() => model.value, () => {
  fulltext.value = model.value
})

const touchstartHandler = (e) => {
  if (!searchInputContainer || !searchInputContainer.value) {
    return
  }
  if (searchInputContainer.value.contains(e.target)) {
    return
  }
  searchInput?.value?.blur()
}

onMounted(() => {
  if (!window) {
    return
  }
  window?.addEventListener("touchstart", touchstartHandler)
})

onUnmounted(() => {
  if (!window) {
    return
  }
  window?.removeEventListener("touchstart", touchstartHandler)
})

const scrollToTop = () => {
  if (!isMobile) {
    return
  }
  window.scrollTo({top: 0, left: 0})
}

const search = () => {
  emits('openSearch')
  nextTick(() => {
    //@ts-ignore
    if (!isFocused.value) {
      searchInput.value?.focus()
    }
  })
}

const focus = () => {
  search()
  isFocused.value = true
}

const blur = () => {
  isFocused.value = false
  if (!model.value) {
    // cancelSearch()
  }
}

const cancelSearch = () => {
  if (model.value) {
    scrollToTop()
  }
  emits('cancelSearch')
  emits('endTyping')
}

const cancelSearchTeleport = () => {
  emits('cancelSearchTeleport')
}

const input = () => {
  emits('startTyping')

  isSearching.value = true
  scrollToTop()

  if (inputDebounceTimeout) {
    clearTimeout(inputDebounceTimeout)
  }

  inputDebounceTimeout = setTimeout(() => {
    model.value = fulltext.value
    emits('endTyping')
    setTimeout(() => {
      isSearching.value = false
    }, 50);
  }, 500)
}
</script>

<style lang="postcss">

.text-search {
  /*@apply !absolute;*/

  /*top: calc(100vh - 50);*/
  /*top: calc(100vh - env(keyboard-inset-height) - 70px);*/
  /*top: 0px;*/
  /*bottom: 20px;*/
}

.search-form__input::placeholder {
  @apply font-light;
}

.search-form__input {
  z-index: 2;
}

.search-form__reset {
  @apply md:opacity-0;
  z-index: 1;
}

.search-form__input:focus:not(:placeholder-shown) ~ .search-form__reset {
  @apply opacity-100;
  z-index: 3;
}

.search-form__input:not(:placeholder-shown) ~ .search-form__reset {
  @apply opacity-100 cursor-pointer;
  z-index: 3;
}

.search-form__input::-webkit-search-cancel-button {
  @apply appearance-none hidden;
}


.search-input {
  @apply rounded-3xl ring-black;

  &:hover {
    @apply ring-black;
  }

  &:focus {
    @apply ring-black;
  }
}
</style>
