<template>
  <div v-if="!hideMenu" class="fixed z-50 bottom-5 inset-x-0 mx-7 pointer-events-none">
    <NavigationMenu :is-search-active="isSearchActive" />
  </div>

  <div
    :class="{'h-[200px] md:h-[180px] lg:h-60': type === HeaderType.FEED && !isSearchTitleActive, 'h-[150px] lg:h-[200px]': type === HeaderType.NORMAL || isSearchTitleActive }"
    class="absolute top-0 left-0 right-0 z-10 w-full bg-black rounded-b-2xl"
  >
  </div>

  <div class="w-full text-white">
    <div class="mx-auto max-w-screen-xl px-5 md:px-8 relative">
      <div class="relative z-20 flex justify-center pt-8 sm.pt-6 lg.pt-8 pb-6">
        <LogoMyFeed :link="logoLink" />
      </div>
      <div class="top-full left-5 md:left-8 absolute z-20">

        <!--search title with go back button-->
        <div
          v-if="isSearchTitleActive"
          class="w-full flex items-center gap-4 lg:gap-5"
          :class="{'hidden md:flex': isSearchActive}"
        >
          <button @click="emits('cancelSearch')" class="w-10 h-10 lg:w-12 lg:h-12">
            <IconsBack />
          </button>
          <span class="text-3xl lg:text-5xl font-bold">{{ translate(translations, 'search.title') }}</span>
        </div>
        <!--title with go back button-->
        <div
          v-else-if="showReturn"
          class="w-full flex items-center gap-4 lg:gap-5"
          :class="{'hidden md:flex': isSearchActive}"
        >
          <button @click="$router.go(-1)" class="w-10  h-10 lg:w-12 lg:h-12">
            <IconsBack />
          </button>
          <span v-if="title" class="text-3xl lg:text-5xl font-bold">{{ title }}</span>
        </div>

        <div
          v-else
          class="w-full"
          :class="{'hidden md:block': isSearchActive}"
        >
          <div class="flex items-center gap-4 lg:gap-5">
            <div class="w-3 h-3 lg:w-[18px] lg:h-[18px] fill-white">
              <slot name="icon">
                <IconsFeed />
              </slot>
            </div>
            <div v-if="showDate" class="">
              <div class="text-neutral-500 text-xs lg:text-lg text-center">{{ getMonthShort() }}</div>
              <div class="text-2xl lg:text-4xl font-bold -mt-1.5 text-center">{{ getDay() }}</div>
            </div>
            <div>
            <div v-if="title" class="text-3xl lg:text-5xl font-bold">{{ title }}</div>
            <div v-if="false && subtitle" class="hidden md:block lg:text-xl">{{ subtitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="sticky top-4 z-30 flex items-center mx-auto max-w-screen-xl px-5 md:px-8 pointer-events-none"
    :class="{
      'pb-[70px] md:pb-14 lg:pb-20': type === HeaderType.FEED && !isSearchTitleActive && !isSearchActive,
      'pb-[65px] md:pb-14 lg:pb-20 -mt-2 md:mt-0': type === HeaderType.FEED && !isSearchTitleActive && isSearchActive,
      'pb-5 md:pb-6 lg:pb-10': (type === HeaderType.NORMAL || isSearchTitleActive) && !isSearchActive,
      'pb-4 md:pb-6 lg:pb-10 -mt-2 md:mt-0': (type === HeaderType.NORMAL || isSearchTitleActive) && isSearchActive,
  }"
  >
    <div
      class="w-full lg:w-9/12 min-h-10 lg:min-h-14"
      :class="{'hidden md:block': isSearchActive}"
    />

    <!-- search input -->
    <transition name="slide-fade">
      <SearchInput
        v-if="showSearchInput && isWeb && (persistedStore.showMenu || isSearchActive)"
        v-model="model"
        :placeholder="translations['search.placeholder']"
        :is-search-active="isSearchActive"
        @open-search="emits('openSearch')"
        @cancel-search="emits('cancelSearch')"
        @start-typing="emits('startTyping')"
        @end-typing="emits('endTyping')"
      />
    </transition>

    <!-- profile picture -->
    <transition name="slide-fade">
    <div
      v-if="persistedStore.showMenu || isSearchActive"
      class="md:w-full lg:w-9/12"
      :class="{'hidden md:block': isSearchActive}"
    >
      <div v-if="showProfilePicture" class="ml-3.5 md:ml-auto w-10 h-10 lg:h-[50px] lg:w-[50px] pointer-events-auto">
        <NuxtLink :to="routes.profile.path">
          <img
            v-if="isLoggedIn && loggedUserImage"
            class="rounded-full border-2 border-white w-full h-full object-cover"
            :src="loggedUserImage"
            :alt="loggedUserName"
            :title="loggedUserName"
          />
          <IconsUserAvatar v-else class="bg-black rounded-full border-2 border-white w-full h-full object-cover p-2" />
        </NuxtLink>
      </div>
    </div>
    </transition>
  </div>

  <IosSearchHackContainer v-if="false" :visible="isSearchActive" v-model="model" />

</template>

<script setup lang="ts">
import type {PropType} from "vue";
import type {HeaderTranslations} from "~/libs/interfaces/Translations";
import {useTranslation} from "~/composables/useTranslation";
import routes from "~/libs/configs/routes";
import HeaderType from "~/libs/interfaces/HeaderType";
const persistedStore = usePersistedStore()
const model = defineModel({required: false})
const emits = defineEmits(['cancelSearch', 'clearSearch', 'openSearch', 'startTyping', 'endTyping'])
const translate = useTranslation
const isWeb = ref(false)

onMounted(() => {
  isWeb.value = !!window
})

const {
  showSearchInput,
  showProfilePicture,
  showDate,
  title,
  subtitle,
  showReturn,
  type,
  isLoggedIn,
  loggedUserImage,
  loggedUserName,
  logoLink,
  isSearchActive,
  isSearchTitleActive,
  translations,
  hideMenu
} = defineProps({
  showSearchInput: {type: Boolean, required: false},
  showProfilePicture: {type: Boolean, required: false},
  hideMenu: {type: Boolean, required: false, default: false},
  showDate: {type: Boolean, required: false, default: false},
  title: {type: String, required: false, default: null},
  subtitle: {type: String, required: false, default: null},
  showReturn: {type: Boolean, required: false, default: false},
  type: {type: String as PropType<HeaderType>, required: false, default: HeaderType.NORMAL},
  isLoggedIn: {type: Boolean, required: false, default: false},
  loggedUserImage: {type: String, required: false},
  loggedUserName: {type: String, required: false},
  logoLink: {type: String, required: false, default: routes.feed.path},
  translations: {type: Object as PropType<HeaderTranslations>, required: false, default: {}},
  isSearchActive: {type: Boolean, required: false, default: false},
  isSearchTitleActive: {type: Boolean, required: false, default: false}
})

const getDay = () => {
  const now = new Date()
  const day = now.getDate()
  if (day < 10) {
    return `0${day}`
  }
  return day
}

const getMonthShort = () => {
  const now = new Date()
  const key = `months.${now.getMonth() + 1}` as keyof HeaderTranslations
  return translate(translations, key)
}
</script>

<style lang="postcss" scoped>
h3 {
  @apply leading-10 text-5xl font-semibold;
}
</style>
